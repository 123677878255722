//Colors

//Texts
$ui-text:             #001b39;
$ui-subtitle:         #637482;

//App
$ui-background:       #f7f7f7;
$ui-card:             #ffffff;

//Colors
$ui-blue:             #1f5688;
$ui-dark_blue:        #001b39;
$ui-light_blue:       #1c78a5;
$ui-red:              #c1473a;
$ui-dark_red:         #441313;
$ui-light_red:        #c69c85;
$ui-green:            #5f9e83;
$ui-dark_green:       #3a6e58;
$ui-yellow:           #ffe255;
$ui-dark_yellow:      #906500;
$ui-grey:             #637482;
$ui-dark_grey:        #787070;
$ui-light_grey:       #d6d6d6;//#F5F6FA;
$ui-lighter_grey:     #f5f6fa;//#F0F0F0; //was #eeeeee;
$ui-white:            #ffffff;

//Score
$score-low:           #1c78a5;
$score-medium:        #001b39;
$score-high:          #c1473a;

//Colors Opacity 0.1
$ui-blue-a10:             rgba(31, 86, 136, 0.1);
$ui-dark_blue-a10:        rgba(0, 26, 56, 0.1);
$ui-light_blue-a10:       rgba(28, 120, 165, 0.1);
$ui-red-a10:              rgba(193, 71, 58, 0.1);
$ui-dark_red-a10:         rgba(68, 19, 19, 0.1);
$ui-light_red-a10:        rgba(198, 156, 133, 0.1);
$ui-green-a10:            rgba(95, 158, 131, 0.1);
$ui-dark_green-a10:       rgba(58, 110, 88, 0.1);
$ui-yellow-a10:           rgba(255, 226, 85, 0.1);
$ui-dark_yellow-a10:      rgba(144, 101, 0, 0.102);
$ui-grey-a10:             rgba(99, 116, 130, 0.1);
$ui-dark_grey-a10:        rgba(120, 112, 112, 0.1);
$ui-light_grey-a10:       rgba(214, 214, 214, 0.1);//#F5F6FA;
$ui-lighter_grey-a10:     rgba(245, 246, 250, 0.1);//#F0F0F0; //was #eeeeee;
$ui-white-a10:            rgba(255, 255, 255, 0.1);

// Components

// Navbar

$navbar-mobile-size:            3.625rem;
$navbar-height:                 57px;


// Widget

$widget-shadow:         0px 0px 7px 1px #e4e4e4;
$widget-radius:         12px;
$widget-bottom-margin:  1.875rem;
$widget-bottom-margin-mobile:  1rem;


$layout-padding:        24px;
$leftbar-cell-size:     72px;
$leftbar-width:         225px;
$margin-leftbar-right:  24px;
$margin-leftbar-left:   35px;
$margin-leftbar-top:    24px;
$margin-leftbar-bottom: 24px;
// $leftbar-container:     calc(#{$margin-leftbar-left} + #{$margin-leftbar-right} + #{$leftbar-width});


$leftbar-hidden-height-mobile:  0;
$leftbar-mobile-z-index:        1000;
$layout-mobile-padding:         0.1875rem;
$leftbar-mobile-top:            $navbar-mobile-size + $layout-mobile-padding;
$leftbar-mobile-left:           $layout-mobile-padding;
$layout-mobile-padding-right:   .375rem;
$leftbar-mobile-width:          calc(100% - #{$layout-mobile-padding-right});
$leftbar-top:                   calc(#{$navbar-height} + #{$layout-padding});


$layout-padding-right: $layout-padding;
$leftbar-left: $layout-padding;
$content-mobile-wrap-pl: 1rem;
$content-mobile-wrap-pr: 1rem;
$content-mobile-wrap-pt: 2rem;
$content-mobile-wrap-pt-fixed-layout: 7rem;
$content-mobile-wrap-pb: $layout-padding;
$content-mobile-wrap: $content-mobile-wrap-pt $content-mobile-wrap-pr $content-mobile-wrap-pb $content-mobile-wrap-pl;
$content-mobile-wrap-pb: $layout-padding;
$content-wrap-pt: $layout-padding;
$content-wrap-pb: $layout-padding;
$content-mobile-wrap-sb-top: calc($navbar-height + $layout-padding + 20px) - 20px;
$vuestic-preloader-left: calc(50% - 140px / 2);
$vuestic-preloader-top: calc(50% - 104px / 2);
$content-wrap-ml: calc(#{$leftbar-left} + #{$leftbar-width} + 35px);
// $sidebar-viewport-height: calc(100vh - #{$navbar-height} - #{$content-wrap-pt} - #{$content-wrap-pb});
$content-mobile-wrap-fixed-layout: $content-mobile-wrap-pt-fixed-layout $content-mobile-wrap-pr $content-mobile-wrap-pb $content-mobile-wrap-pl;


$sidebar-width-expanded: 237px;
$sidebar-width-collapsed: 50px;
$layout-padding-size: 32px;
$layout-padding-size-mobile: 16px;

$sidebar-footer-height: 80px;
$sidebar-back-height: 45px;
$sidebar-logo-padding-top: 20px;
$sidebar-logo-height: 30px;