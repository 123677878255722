
.widget-box {
  background-color: $ui-card;
  border-radius: $widget-radius;
  -webkit-box-shadow: $widget-shadow;
  -moz-box-shadow: $widget-shadow;
  box-shadow: $widget-shadow;
}

@mixin avatar($size) {
  width: $size;
  height: $size;
  background-color: var(--color);
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;

  .initials {
    position: relative;
    top: 25%;//$size*0.25;
    font-size: $size*0.35;
    line-height: $size*0.5;
    color: #fff;
    font-family: "Campton-Medium";
  }
}

@mixin notme-btn($color) {
  border: none;
  border-radius: 25px;
  color: $ui-white !important;
  background-color: $color !important;
  font-family: "Campton-Medium";
  height: 40px;
  transition: opacity .3s ease;
  &:hover {
    -webkit-box-shadow: $widget-shadow;
    -moz-box-shadow: $widget-shadow;
    box-shadow: $widget-shadow;
    color: $ui-card !important;
    background-color: $color !important;
    opacity: .8;
    border: none;
  }
  &:focus {
    border: none;
    color: $ui-card !important;
    background-color: $color !important;
  }
  &.is-disabled {
    border: none;
    opacity: 0.65;

  }
}


@mixin notme-btn-square($color) {
  border: none;
  border-radius: 0px;
  color: $ui-white !important;
  background-color: $color !important;
  font-family: "Campton-Medium";
  height: 40px;
  transition: opacity .3s ease;
  width: 100%;
  &:hover {
    -webkit-box-shadow: $widget-shadow;
    -moz-box-shadow: $widget-shadow;
    box-shadow: $widget-shadow;
    color: $ui-card !important;
    background-color: $color !important;
    opacity: .8;
    border: none;
  }
  &:focus {
    border: none;
    color: $ui-card !important;
    background-color: $color !important;
  }
  &.is-disabled {
    border: none;
    opacity: 0.65;

  }
}

.btn-blue {
  @include notme-btn($ui-dark_blue);
}
.btn-red {
  @include notme-btn($ui-red);
}
.btn-green {
  @include notme-btn($ui-dark_green);
}
.btn-green-2 {
  @include notme-btn($ui-green);
}
.btn-light-blue {
  @include notme-btn($ui-light_blue);
}
.btn-grey {
  @include notme-btn($ui-grey);
}
.btn-light-grey {
  @include notme-btn($ui-light_grey);
}
.notme-cancel {
  @include notme-btn($ui-red);
}

.btn-red-fill {
  @include notme-btn-square($ui-red);
}
.btn-blue-fill {
  @include notme-btn-square($ui-dark_blue);
}
.btn-blue-2-fill {
  @include notme-btn-square($ui-blue);
}
.btn-green-fill {
  @include notme-btn-square($ui-green);
}

.notme-ok {
  @include notme-btn($ui-light_blue);
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color;
  background: rgba($color, $opacity);
}

.dashboard-cell {
  background-color: $ui-card;
  border-radius: 12px;
  margin-bottom: 10px;
  transition: all .1s ease-in-out;
  text-align: left;
  margin-right: 0px;
  margin-left: 0px;
  -webkit-box-shadow: $widget-shadow;
  -moz-box-shadow: $widget-shadow;
  box-shadow: $widget-shadow;

  a {
    color: $ui-text;
    text-decoration: none;
  }
  p {
    margin-bottom: 0px;
  }
}